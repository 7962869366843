export const Features = (props) => {
  var wt=""
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Download</h2>
        </div>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
                {' '}
                <a href='https://play.google.com/store/apps/details?id=tofanitty.trk'>
                  <img src='img/google-play-badge.png' className='android'/>
                </a>
              </div>
              <div className='col-xs-12 col-md-6'>
                {' '}
                <a href='https://apps.apple.com/us/app/the-running-knight/id1601823962'>
                  <img src='img/app-store-badge.png' className='iPhone'/>
                </a>
              </div>
        </div>
      </div>
    </div>
  )
}

/* {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-6'>
                  {' '}
                  <a href={d.link}>
                    <img src={d.photo} className={d.type}/>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </a>
                </div>
              ))
            : 'Loading...'} */
